// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-posts-page-layout-js": () => import("./../../../src/components/posts-page-layout.js" /* webpackChunkName: "component---src-components-posts-page-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-future-index-js": () => import("./../../../src/pages/future_index.js" /* webpackChunkName: "component---src-pages-future-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-js": () => import("./../../../src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-questions-js": () => import("./../../../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */)
}

